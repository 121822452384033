import React from 'react';
import Navbar from '../components/NavBar';
import Banner from '../components/Banner/Banner';
import BannerData from '../components/Banner/BannerData';
import SectionAboutUs from '../components/SectionAboutUs';
import SectionOffers from '../components/SectionOffers';
import SectionTeam from '../components/SectionTeam';
import Points from '../components/Points';
import ServiceData from '../components/Service/ServiceData';
import SectionChoose from '../components/SectionChoose';
import FooterData from '../components/Footer/FooterData';
import Footer from '../components/Footer/Footer';

export const Home = () => {
    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <Banner BannerData={BannerData}/>                   {/* HEADER ENTREE SUR LE SITE AVEC VIDEO ET TITLE */}
            <Points ServiceData={ServiceData} id="1"/>          {/* 3 PREMIERS POINTS AVEC ICONES */}
            <SectionAboutUs/>                                   {/* SECTION A PROPOS AVEC IMAGE ET TEXTE */}
            <Points ServiceData={ServiceData} id="2"/>          {/* 3 DEUXIEMES POINTS AVEC ICONES */}
            <SectionOffers/>                                    {/* SECTION NOS OFFRES AVEC IMAGE ET TEXTE */}
            <Points ServiceData={ServiceData} id="3"/>          {/* 3 TROISIEMES POINTS AVEC ICONES */}
            <SectionTeam/>                                      {/* SECTION NOTRE EQUIPE AVEC IMAGE ET TEXTE */}
            <SectionChoose/>                                    {/* SECTION CHOISIR ENTRE ECOLE ET PERSONNEL DE SANTE */}
            <Footer FooterData={FooterData}/>
        </div>
    )
}