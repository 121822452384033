const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    ServiceOne: [
        {
            id : 1,
            shape : '101-cardiogram-2.png',
            icon : '101-cardiogram-2.png',
            text : 'Accès illimité',
            description: 'Un accès illimité avec une assistance technique et pédagogique.',
        },
        {
            id : 2,
            shape : '101-doctor-1.png',
            icon : 'ti-layout-grid2',
            text : 'Ergonomique et flexible',
            description: 'La plateforme est ergonomique, flexible, et disponible sur tous les supports.',
        },
        {
            id : 3,
            shape : '101-doctor-2.png',
            icon : 'ti-stats-up',
            text : 'Suivi pédagogique complet',
            description: 'Nous offrons un suivi pédagogique complet, durant toute la progression jusqu’au résultat.',
        }
    ],
    ServiceTwo: [
        {
            id : 1,
            shape : '101-hospital.png',
            icon : 'ti-user',
            text : 'Contenus créés par nos équipes',
            description: 'Notre plateforme et nos contenus sont créés par nos équipes pour la formation dans le milieu médical.',
        },
        {
            id : 2,
            shape : '101-injection.png',
            icon : 'ti-support',
            text : 'Programme sur mesure',
            description: 'Le programme est sur mesure et adapté aux besoins et référentiels des différentes filières des écoles de santé, et pour la formation continue en milieu hospitalier.',
        },
        {
            id : 3,
            shape : '101-injury.png',
            icon : 'ti-bar-chart',
            text : 'Suivi de progression détaillé',
            description: 'Notre système XAPI nous permet d’analyser toutes les actions des utilisateurs sur notre plateforme et suivre leurs progressions en détail sur chaque module.',
        },
    ],
    ServiceThree: [
        {
            id : 1,
            shape : '101-newborn.png',
            icon : 'ti-check',
            text : 'Contenus de qualité',
            description: 'Le contenu de notre plateforme est créé sur le logiciel d’édition StoryLine, logiciel de référence pour la création de contenu E-Learning.',
        },
        {
            id : 2,
            shape : '101-worldwide.png',
            icon : 'ti-pie-chart',
            text : 'Ressources pédagogiques variées',
            description: 'Nos ressources pédagogiques sont variées (Vocabulaire, Grammaire, Étude de Cas, …), réparties en 3 niveaux pour l’utilisateur avec des contenus illustrés, vidéos, enregistrements, schémas, plaquettes anatomiques.',
        },
        {
            id : 3,
            shape : '101-surgeon-1.png',
            icon : 'ti-desktop',
            text : 'Accompagnement sur l\'E-Learning',
            description: 'Nous accompagnons et formons vos utilisateurs à la prise en main et à l’utilisation du logiciel E-Learning.',
        }
    ],
    agtext1: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext2: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext3: 'Starkers dropped a clanger lurgy is cack excuse my French what.',
    pServiceTitle: 'Our Services',
    pServiceDetails: 'Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!',
    PaymentService: [
        {
            id: 1,
            image: 'icon4.png',
            title: 'Safer',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 2,
            image: 'icon7.png',
            title: 'Flexible',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 3,
            image: 'icon5.png',
            title: 'Convinient',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 4,
            image: 'icon8.png',
            title: 'Protected',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 5,
            image: 'icon6.png',
            title: 'World wide',
            description: 'Well at public scho cheeky bugger grub burke.'
        }
    ],
    HRService: [
        {
            id: '1',
            HRtitles : 'Export Presets',
            HRdescription: 'Un accès illimité avec une assistance technique et pédagogique',
            Hicon: 'panel',
            rclass : 'pr_70',
            iclass: 'icon_one',
        },
        {
            id: '2',
            HRtitles : 'Grid and Guides',
            HRdescription: 'La plateforme est ergonomique, flexible, et disponible sur tous les supports.',
            Hicon: 'layout-grid2',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles : 'Pixel Precision',
            HRdescription: 'Nous offrons un suivi pédagogique complet, durant toute la progression jusqu’au résultat.',
            Hicon: 'fullscreen',
            rclass : 'pl_70',
            iclass: 'icon_three',
        },
        {
            id: '4',
            HRtitles : 'Vector Editing',
            HRdescription: 'Notre plateforme et nos contenus sont créés par nos équipes pour la formation dans le milieu médical.',
            Hicon: 'vector',
            rclass : 'pr_70',
            iclass: 'icon_four',
        },
        {
            id: '5',
            HRtitles : 'Cloud Service',
            HRdescription: 'Le programme est sur mesure et adapté aux besoins et référentiels des différentes filières des écoles de santé, et pour la formation continue en milieu hospitalier.',
            Hicon: 'cloud-down',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_five',
        },
        {
            id: '6',
            HRtitles : 'Iterate at Speed',
            HRdescription: 'Notre système XAPI nous permet d’analyser toutes les actions des utilisateurs sur notre plateforme et suivre leurs progressions en détail sur chaque module.',
            Hicon: 'bolt',
            rclass : 'pl_70',
            iclass: 'icon_six',
        },
    ],
    rpost:[
        {
            id: '01',
            image: 'blog-grid/post_1.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        },
        {
            id: '02',
            image: 'blog-grid/post_2.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 10, 2019'
        },
        {
            id: '03',
            image: 'blog-grid/post_3.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'jan 10, 2019'
        },
        {
            id: '04',
            image: 'blog-grid/post_4.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        }
    ],
    Blist: [
        {
            id: '01',
            image: 'blog-grid/blog_list1.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '02',
            image: 'blog-grid/blog_list2.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'June'
        },
        {
            id: '03',
            image: 'blog-grid/blog_list3.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '04',
            image: 'blog-grid/blog_list4.jpg',
            btitle: 'Bloke cracking goal the full monty get stuffed mate posh.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '01',
            Month: 'July'
        },
    ],
    work: [
        {
            id: '01',
            icon: 'ti-settings one',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '02',
            icon: 'ti-heart-broken two',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '03',
            icon: 'ti-target  three',
            title: 'Live page builder',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        }
    ]
}
export default ServiceData;