import React, {Component} from 'react';
import Stserviceitems from './Service/Sservice/StserviceItem';
import Reveal from 'react-reveal/Reveal';

class Points extends Component {
    render(){
        let ServiceData = this.props.ServiceData;
        let service;
        if (this.props.id === '1') {
            service = <div className="row">
                        {ServiceData.ServiceOne.map(items => {
                            return(
                                <Reveal effect="fadeInUp" key={items.id}>
                                    <div className="col">
                                        <Stserviceitems iShap={items.shape} text={items.text} description={items.description} btn={items.btn} bicon={items.bicon}/>
                                    </div>
                                </Reveal>
                            )
                        })}
                    </div>
        } else if (this.props.id === '2') {
            service = <div className="row">
                        {ServiceData.ServiceTwo.map(items => {
                            return(
                                <Reveal effect="fadeInUp" key={items.id}>
                                    <div className="col">
                                        <Stserviceitems iShap={items.shape} text={items.text} description={items.description} btn={items.btn} bicon={items.bicon}/>
                                    </div>
                                </Reveal>
                            )
                        })}
                    </div>
        } else if (this.props.id === '3') {
            service =  <div className="row">
                        {ServiceData.ServiceThree.map(items => {
                            return(
                                <Reveal effect="fadeInUp" key={items.id}>
                                    <div className="col">
                                        <Stserviceitems iShap={items.shape} text={items.text} description={items.description} btn={items.btn} bicon={items.bicon}/>
                                    </div>
                                </Reveal>
                            )
                        })}
                    </div>
        }
        return(
            <section className="agency_service_area bg_color">
                <div className="container custom_container">
                    {service}
                </div>
            </section>
        )
    }
}

export default Points;