import React, {Component} from 'react';
import Reveal from "react-reveal"
import {Link} from 'react-router-dom';

class SectionAboutUs extends Component{
    render(){
        return(
            <section className="agency_featured_area bg_color">
                <div className="container">
                    <div className="features_info">
                        <section className="app_featured_area" id="features">
                            <div className={`agency_featured_item row agency_featured_item_two`}>
                                <div className="col-lg-6">
                                    <Reveal effect="fadeInLeft">
                                            <div className="agency_featured_img text-left">
                                                <img src={require ("../img/home4/work1.png")} alt=""/>
                                            </div>
                                    </Reveal>
                                </div>
                                <div className="col-lg-6">
                                    <div className={`agency_featured_content wow fadeInLeft pl_100`} data-wow-delay="0.6s">
                                    <Reveal effect="fadeInRight">
                                        <div className="dot"><span className="dot1"></span><span className="dot2"></span></div>
                                        <h2 className="title-about">A PROPOS</h2>
                                        <h4 className="subtitle-about">Une mise en place <span>facile</span> et <span>rapide</span></h4>
                                        <p>
                                            Morbi fermentum ipsum id arcu iaculis, laoreet lacinia quam rutrum.
                                            Aliquam a justo lacinia, placerat magna eget, maximus leo. Integer venenatis magna odio, ac rutrum orci finibus quis.
                                            Nunc pharetra nec nunc nec luctus. Aenean auctor fermentum orci quis eleifend. Nullam posuere ac nibh eget vulputate.
                                            Morbi vel velit eu urna luctus ultricies a id dolor.
                                        </p>
                                        <Link exact title="Team" to='/Pages/About'>
                                            <div className="buttonMore">
                                                Voir plus
                                            </div>
                                        </Link>
                                    </Reveal>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        )
    }
}
export default SectionAboutUs;