import React from 'react';
import Navbar from '../components/NavBar';
import Breadcrumb from '../components/Breadcrumb';
import Sservice from '../components/Service/Sservice/Sservice';
import Service from '../components/Service/ServiceAboutUs';
import Partner from '../components/Partner';
import Footer from '../components/Footer/Footer';
import ServiceData from '../components/Service/ServiceData';
import FooterData from '../components/Footer/FooterData';

const AboutUs = () => {
    return (
        <div className="body_wrapper">
            <Navbar mClass="menu_four" nClass="pl_120 w_menu ml-auto mr-auto" cClass="custom_container p0" hbtnClass="menu_cus" />
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="À propos de nous" />
            <Service/>
            <Footer FooterData={FooterData}/>
        </div>
    )
}
export default AboutUs;