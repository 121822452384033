import React from 'react';
import Navbar from '../components/NavBar';
import Breadcrumb from '../components/Breadcrumb';
import HRService from '../components/Service/HRService';
import PrototypeService from '../components/Service/Sservice/PrototypeService';
import Partner from '../components/Partner';
import ServiceSubscribe from '../components/ServiceSubscribe';
import Footer from '../components/Footer/Footer';
import ServiceData from '../components/Service/ServiceData';
import FooterData from '../components/Footer/FooterData';

const Offers = () => {
    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" nClass="pl_120 w_menu ml-auto mr-auto" cClass="custom_container p0" hbtnClass="menu_cus" />
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Nos offres"/>
            <HRService ServiceData={ServiceData}/>
            <PrototypeService/>
            <Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>
            <Footer fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
}
export default Offers;