import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';
import $ from 'jquery/';
import 'malihu-custom-scrollbar-plugin/';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import 'jquery-mousewheel';

class Navbar extends Component {
    componentDidMount() {
        $(window).on("load",function(){
            $('.mega_menu_two .scroll').mCustomScrollbar({
                mouseWheelPixels: 0,
                scrollInertia: 0
            })
        })
    }
    render() {
        var {mClass, nClass, cClass, slogo } =this.props;
        return (
            <Sticky top={0} innerZ={9999} className="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/favicon2.png")} width="86" height="52" alt=""/>
                            <img src={require("../img/favicon2.png")} width="86" height="52" alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                                    <Link exact title="Home" className="nav-link" to='/'>
                                        ACCUEIL
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link exact title="Pages" className="nav-link" to='/Pages/About'>
                                        À PROPOS
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact title="Service" className="nav-link" to='/offres'>
                                        NOS OFFRES
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink title="Pricing" className="nav-link" to="/Pages/Contact">
                                        CONTACT
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default Navbar;