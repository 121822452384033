import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
import ReactTextCollapse from 'react-text-collapse';

const TEXT_COLLAPSE_OPTIONS = {
    collapse: false,
    collapseText: 'Voir plus',
    expandText: 'Voir moins',
    minHeight: 90,
    maxHeight: 330,
    textStyle: {
        fontSize: '18px',
        color: '#fff',
        borderColor: '#fff',
        marginTop: '20px',
        fontWeight: '500',
        fontFamily: "Poppins, sans-serif",
        background: '#41b2e4',
        boxShadow: "0px 20px 30px 0px rgba(12, 0, 46, 0.1)",
        borderRadius: '3px',
        padding: '11px 40px',
        border: "1px solid #41b2e4",
        WebkitTransition: "all 0.2s linear",
        Otransition: "all 0.2s linear",
        transition: "all 0.2s linear",
        cursor: 'pointer',
        overflow: 'hidden',
        display: 'inline-block',
        zIndex: '1',
    }
}

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }
    render(){
        return(
            <section className="agency_banner_area bg_color">
                <img className="banner_shap" src={require('../../img/home4/home-bg.png')} alt=""/>
                <div className="container custom_container">
                    <div className="row">
                        <div className="col-lg-5 d-flex align-items-center">
                            <div className="agency_content">
                                <Reveal effect="fadeInUp">
                                    <React.Fragment>
                                        <div class="row wow fadeInLeft" data-wow-delay="0.2s" style={{marginBottom: "20px", marginLeft: "2px"}}>
                                            <img src={require("../../img/favicon2.png")} width="285" height="165" style={{opacity: '0.8'}} />
                                        </div>
                                        {/* <h1 className="f_700 t_color3 wow fadeInLeft" data-wow-delay="0.3s">
                                            Medical World
                                        </h1> */}
                                        <h2 className="f_700 t_color3 mb-30 wow fadeInLeft" data-wow-delay="0.4s">
                                            Soignez votre anglais !
                                        </h2>
                                        <div>
                                            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                                                <p className="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.5s" style={{fontSize: "20px"}}>
                                                    Soignez votre anglais avec Medical World, la plateforme d’apprentissage spécialisée en anglais médical et paramédical !<br />
                                                    Créée en 2015 par EnglishWorld, un organisme de formation en anglais professionnel, la plateforme offre des contenus de
                                                    qualité et sur mesure pour chaque domaine médical.
                                                    Utilisée dans les écoles de santé (IFSI, IFMK, IADE…) et pour la formation continue dans les hôpitaux,
                                                    sa mise en place est facile et rapide. Nos équipes vous garantissent un accompagnement personnalisé et un suivi complet
                                                    pour les apprenants et les responsables de la formation.
                                                </p>
                                            </ReactTextCollapse>
                                        </div>
                                    </React.Fragment>
                                </Reveal>
                            </div>
                        </div>
                        <div className="col-lg-7 text-right video_banner">
                            <Reveal effect="fadeIn">
                                <iframe className="protype_img wow fadeIn" data-wow-delay="0.1s" id="video-animate" width="720" height="400" src="https://www.youtube.com/embed/GrLrEunLROA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" title="Vidéo English World"></iframe>
                            </Reveal>
                        </div>
                    </div>
                    {/*<div className="partner_logo">
                        {
                            BannerData.ClientsLogo.map(item =>{
                                return(
                                    <Reveal key={item.id} effect="fadeInLeft" duration={1500}>
                                        <div className="p_logo_item">
                                            <a href="/#"><img src={require("../../img/home3/" + item.image)} alt=""/></a>
                                        </div>
                                    </Reveal>
                                )
                            })
                        }
                    </div>*/}
                </div>
            </section>
        )
    }
}
export default Banner;