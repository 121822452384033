import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';

const ServiceAboutUs = () => {
    return(
        <React.Fragment>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/home4/work3.png')} alt="" style={{paddingTop: "40px", paddingLeft: "60px"}}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>Un accompagnement personnalisé</h2>
                                    <p>Faff about only a quid blower I don't want no agro bleeding chimney pot burke tosser cras nice one boot fanny.!</p>
                                    <div className="media seo_features_item">
                                        <div className="icon"><img src={require('../../img/seo/icon4.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>Reporting & Analysis</h3>
                                            <p>Naff are you taking the piss say blow off faff about wellies richard.</p>
                                        </div>
                                    </div>
                                    <div className="media seo_features_item">
                                        <div className="icon two"><img src={require('../../img/seo/icon3.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>Reporting & Analysis</h3>
                                            <p>Naff are you taking the piss say blow off faff about wellies richard.</p>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_fact_area sec_pad" style={{marginTop: "60px"}}>
                <div className="home_bubble">
                    <div className="bubble b_one"></div>
                    <div className="bubble b_three"></div>
                    <div className="bubble b_four"></div>
                    <div className="bubble b_six"></div>
                    <div className="triangle b_eight" data-parallax='{"x": 120, "y": -10}'><img src="img/seo/triangle_one.png" alt=""/></div>
                </div>
                <div className="container">
                    <SeoTitle Title='Medical World est une plateforme E-learning interactive'/>
                    <div className="seo_fact_info">
                        <div className="seo_fact_item">
                            <div className="text">
                                <div className="counter one">85%</div>
                                <p>d'étudiants satisfaits</p>
                            </div>
                        </div>
                        <div className="seo_fact_item">
                            <div className="text">
                                <div className="counter two">276</div>
                                <p>Projects</p>
                            </div>
                        </div>
                        <div className="seo_fact_item">
                            <div className="text">
                                <div className="counter three">102</div>
                                <p>SEO Winners</p>
                            </div>
                        </div>
                        <div className="seo_fact_item last">
                            <div className="text">
                                <div className="counter four">93</div>
                                <p>Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_call_to_action_area sec_pad" style={{marginTop: "60px", marginBottom: "60px"}}>
                <div className="container">
                    <div className="seo_call_action_text">
                        <h2>Ready to get started?<br/> lt's fast, free and very easy!</h2>
                        <a href="/" className="about_btn">Get a free Estimate</a>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ServiceAboutUs;
