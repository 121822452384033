import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import AgencyBanner from '../components/Banner/AgencyBanner';
import BannerData from '../components/Banner/BannerData';
import AppFeaturesTwo from '../components/Features/AppFeaturesTwo';
import MarketingService from '../components/Service/MarketingService';
import MarketingServiceTwo from '../components/Service/MarketingServiceTwo';
import ServiceData from '../components/Service/ServiceData';
import Features from '../components/Features/Features';
import FooterData from '../components/Footer/FooterData';
import Cprogress from '../components/CircleProgressbar';
import Footer from '../components/Footer/Footer';


const DigitalMarketing = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}/>
            <MarketingService ServiceData={ServiceData}/>
            <AppFeaturesTwo/>
            <MarketingServiceTwo ServiceData={ServiceData}/>
            <AppFeaturesTwo/>
            <MarketingService ServiceData={ServiceData}/>
            <Cprogress/>
            {/*<AgencyAbout ServiceData={ServiceData}/>*/}
            <Features/>
            {/*<MarketingTestimonial BannerData={BannerData}/>*/}
            {/*<AgencyAction/>*/}
            <Footer FooterData={FooterData}/>
        </div>
    )
}
export default DigitalMarketing;