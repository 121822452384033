import React, {Component} from 'react';
import Reveal from "react-reveal"
import {Link} from 'react-router-dom';

class SectionChoose extends Component{
    render(){
        return(
            <section className="agency_featured_content bg_color" style={{paddingTop: "50px", paddingBottom: "20px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <Reveal effect="fadeInLeft">
                                <Link exact title="Team" to='/Pages/Service'>
                                    <div class="service_item" style={{width: '75%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}>
                                        <img src={require('../img/home4/school.svg')} alt="" style={{width: '253px', heigth: '186px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
                                        <h4 class="f_600 f_size_20 l_height28 t_color2 mb_20 mt-20" style={{textAlign: 'center', paddingTop: '50px'}}>
                                            Pour les écoles
                                        </h4>
                                        <div className="buttonChoose">
                                            Voir plus
                                        </div>
                                    </div>
                                </Link>
                            </Reveal>
                        </div>
                        <div className="col">
                            <Reveal effect="fadeInRight">
                                <Link exact title="Team" to='/Pages/Service'>
                                    <div class="service_item" style={{width: '75%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}>
                                        <img src={require('../img/home4/doctor.svg')} alt="" style={{width: '280px', heigth: '214px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
                                        <h4 class="f_600 f_size_20 l_height28 t_color2 mb_20" style={{textAlign: 'center', paddingTop: '50px'}}>
                                            Pour le personnel de santé
                                        </h4>
                                        <div className="buttonChoose">
                                            Voir plus
                                        </div>
                                    </div>
                                </Link>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionChoose;