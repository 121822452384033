import React, {Component} from 'react';

class Contacts extends Component {
    render(){
        return(
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contactez votre conseiller en formation</h6>
                                <p className="f_400 f_size_15">Téléphone : +33 3 83 30 77 62</p>
                            </div>
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Nos adresses</h6>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">PARIS</span>49 rue de Ponthieu</p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">METZ</span>5 rue Pasteur</p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">NANCY</span>8 rue Georges de la Tour</p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">LUXEMBOURG</span>68 Avenue de la Liberté</p>
                            </div>
                        </div>
                        <div className="contact_form col-lg-9">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Laissez-nous un message</h2>
                            <form action="#" className="contact_form_box" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" id="name" name="name" placeholder="Votre nom"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" name="email" id="email" placeholder="Votre email"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" id="subject" name="subject" placeholder="Le sujet"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea name="message" id="message" cols="30" rows="10" placeholder="Entrez votre message . . ."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn_three">Envoyez votre message</button>
                            </form>
                            <div id="success">Votre message a bien été envoyé</div>
                            <div id="error">Votre message n'a pas pu s'envoyer :( Essayez une nouvelle fois</div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contacts;