import React , {Component} from 'react';
import {Link} from 'react-router-dom';
import Reveal from 'react-reveal/Reveal'
class Footer extends Component {
    render(){
        let FooterData = this.props.FooterData;
        return(
            <footer className="bg_color">
                <div className="new_footer_top">
                    <div className="container">
                        <div className="row">
                        {
                                FooterData.CompanyWidget.map(widget=>{
                                    return(
                                        <Reveal effect="fadeInUp" key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                                                <a href="index.html" className="f-logo"><img src={require('../../img/favicon2-small.png')} alt=""/></a>
                                                    <div className="widget-wrap">
                                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Email:</span> <a href="mailto:contact@englishworld.fr" className="f_400">contact@englishworld.fr</a></p>
                                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Phone:</span> <a href="tel:0383307762" className="f_400">+33 3 83 30 77 62</a></p>
                                                    </div>
                                                    <form action="#" className="f_subscribe mailchimp" method="post">
                                                        <input type="text" name="EMAIL" className="form-control memail" placeholder="Email"/>
                                                        <button className="btn btn-submit" type="submit"><i className="ti-arrow-right"></i></button>
                                                    </form>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                            <Reveal effect="fadeInLeft" duration={500}>
                                <div className="col-lg-3 col-md-6">
                                    <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                        <h3 className="f-title f_600 t_color f_size_18">À PROPOS</h3>
                                    </div>
                                </div>
                            </Reveal>
                            <Reveal effect="fadeInLeft" duration={500}>
                                <div className="col-lg-3 col-md-6">
                                    <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                        <h3 className="f-title f_600 t_color f_size_18">NOS OFFRES</h3>
                                    </div>
                                </div>
                            </Reveal>
                            <Reveal effect="fadeInLeft" duration={500}>
                                <div className="col-lg-3 col-md-6">
                                    <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                        <h3 className="f-title f_600 t_color f_size_18">CONTACT</h3>
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                        
                    </div>
                    <div className="footer_bg">
                        <div className="footer_bg_one"></div>
                        <div className="footer_bg_two"></div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;